@import '../../styles/_variables.scss';
@import '../../styles/_colors.scss';
@import '../../styles/_colorsMiljostatus.scss';
@import '../../styles/_mixins.scss';
@import '../../styles/_utilities.scss';

.c_header-breadcrumbs {
	/* position: absolute;
	left: 160px;
	bottom: 1.85em;
	color: $color-miljostatus--dark-blue-1;
	max-width: calc(100% - 160px - 15em);
	z-index: 1; */

	a, link, link:visited {
		color: $color-miljostatus--dark-blue-1;
	}

	&__list {
		display: block;
		padding: 0;
		margin: 1em 0 0 0;

		&__item {
			display: inline-block;
			margin-left: 0.4em;
			line-height: 1.35;
			font-family: $base-font-family;

			&:first-of-type {
				margin: 0;
			}

			&:last-of-type {
				color: $color--emperor;
			}

			a {
				@include hover() {
					color: $color-miljostatus--dark-blue-1--hover;
					text-decoration: none;
				}
			}

			img {
				opacity: 0.5;
				width: 0.3em;
				height: 0.4em;
				margin-left: 0.4em;
			}
		}
	}
}
