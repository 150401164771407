@charset "UTF-8";

@import '_variables.scss';
@import '_colorsMiljostatus.scss';
@import '_typography.scss';
@import '_grid.scss';
@import '_utilities.scss';

* {
	outline-offset: $focus-outline-offset;
	&:focus,
	&:active {
		outline-color: $color-miljostatus--focus-orange;
	}
	&:focus {
		outline-style: solid;
		outline-width: $focus-outline-width;
	}
}

body {
	background-color: $color-miljostatus--background-white;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;

	button {
		font-family: $base-font-family;
	}
}

@media (max-width: 768px) {
	body.u_master-nav-menu-open {
		overflow: hidden;
	}
}

.no-script {
    margin: 0 auto;
    width: 90%;
}

.app-wrapper {
	width: 100%;
	height: 100%;
}

.skipLink {
	position: absolute;
	overflow: hidden;
	height: 1px; width: 1px;
	margin: -1px; padding: 0; border: 0;
	background-color: $color-miljostatus--pale-grey;
	color: $color-miljostatus--black;
	opacity: 0;

	&:focus {
		opacity: 1;
		padding: 0.5em;
		overflow: auto;
		height: auto;
		width: auto;
		margin: 0;
		z-index: 3;
		outline: $color-miljostatus--focus-orange solid $focus-outline-width;
		outline-offset: $focus-outline-offset;
		transition: all 0.4s ease;
	}

	&--top {
		top: 0.5em;
		left: 0.5em;
		z-index: 1;

		&:focus {
			animation: slideInSkipLinkTop 0.4s 0s forwards;
		}
	}

	&--bottom {
		bottom: 0.5em;
		right: 0.5em;

		&:focus {
			outline: white solid $focus-outline-width;
			animation: slideInSkipLinkBottom 0.4s 0s forwards;
		} 
	}
}

.c_mstatus-main {
	position: relative;
	z-index: 2;
	/* animation: fadeInMain 0.75s 0s ease forwards; */
}

.c_loading-main {
	.c_loading-spinner {
		margin-top: 7.5em;
		margin-bottom: 7.5em;
	}
}

@keyframes slideInSkipLinkTop {
	from {
		transform: translate3d(-1em, -1em, 0);
	}
	to {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes slideInSkipLinkBottom {
	from {
		transform: translate3d(1em, 1em, 0);
	}
	to {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fadeInMain {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
