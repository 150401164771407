.c_loading-spinner {
	margin: 4em auto;
	img {
		display: block;
		width: 100px;
		height: 100px;
		margin: 0 auto;
		animation: spinAnimation 1s linear infinite;
	}
}

@keyframes spinAnimation {
	from { transform: rotate(0deg) }
	to { transform: rotate(360deg) }
}