@import '../../styles/_variables.scss';
@import '../../styles/_colors.scss';
@import '../../styles/_colorsMiljostatus.scss';
@import '../../styles/_mixins.scss';

.c_master-nav {
	height: 100%;
	overflow: hidden;

	@media (min-width: $large-screen-min) {
		position: absolute;
		top: 0;
		right: 0;
		opacity: 0;
		margin: 0;
	}

	&__bottom-gradient {
		width: 100%;
		display: inline-block;
		height: ($container-padding * 4.5);
		position: absolute;
		bottom: 0;
		left: 0;
		background: -moz-linear-gradient(top, rgba($color-miljostatus--grey-blue-3,0) 0%, rgba($color-miljostatus--grey-blue-3,1) 50%, rgba($color-miljostatus--grey-blue-3,1) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, rgba($color-miljostatus--grey-blue-3,0) 0%,rgba($color-miljostatus--grey-blue-3,1) 50%,rgba($color-miljostatus--grey-blue-3,1) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, rgba($color-miljostatus--grey-blue-3,0) 0%,rgba($color-miljostatus--grey-blue-3,1) 50%,rgba($color-miljostatus--grey-blue-3,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$color-miljostatus--grey-blue-3, endColorstr=$color-miljostatus--grey-blue-3,GradientType=0 ); /* IE6-9 */

		@media (min-width: $large-screen-min) {
			height: ($container-padding * 6);
		}
	}

	&--overflowing .c_master-nav__bottom-gradient {
		&:before {
			content: '';
			background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMS44OSAxMy41Ij48dGl0bGU+aWNvbl9zb3J0X3RhYmxlPC90aXRsZT48cGF0aCBkPSJNMTEuNjcsN2EuNzQuNzQsMCwwLDAtMS4wNiwwTDYuNjksMTAuOTRWLjc1YS43NS43NSwwLDAsMC0xLjUsMFYxMC45NEwxLjI4LDdBLjc0Ljc0LDAsMCwwLC4yMiw3YS43NS43NSwwLDAsMCwwLDEuMDZsNS4xOSw1LjE5YS44Mi44MiwwLDAsMCwuMjUuMTYuNzEuNzEsMCwwLDAsLjI4LjA2LjcyLjcyLDAsMCwwLC4yOS0uMDYuNjcuNjcsMCwwLDAsLjI0LS4xNmw1LjItNS4xOUEuNzUuNzUsMCwwLDAsMTEuNjcsN1oiLz48L3N2Zz4=);
			background-repeat: no-repeat;
			width: 1em;
			height: 1em;
			display: inline-block;
			position: absolute;
			left: 0;
			right: 0;
			margin: 0 auto;
			bottom: 1em;
			opacity: 0.75;

			@media (min-width: $large-screen-min) {
				width: 1.25em;
				height: 1.25em;
			}
		}
	}

	&--open {
		width: 100%;
		top: 0;

		@media (min-width: $large-screen-min) {
			padding-bottom: 3em;
		}

		.c_master-nav__bottom-gradient:before {
			animation: bounce 4s 0s 1 forwards ease;
		}

		.c_master-nav__menu {
			.c_nav-section-list {
				&__level-btns-wrapper {
					opacity: 1;
					transition: opacity 0.4s ease 0s;
				}
			}
		}
	}

	@keyframes bounce {
		0% { transform: translateY(0); }
		20% { transform: translateY(-1em); }
		40% { transform: translateY(0); }
		60% { transform: translateY(-1em); }
		80% { transform: translateY(0); opacity: 0.75; }
		100% { transform: translateY(-1em); opacity: 0; }
	}

	.bm-overlay {
		background: transparent !important;
		@media (max-width: $small-screen-max) {
			top: 0;
		}
	}

	.bm-menu-wrap {
		@media (max-width: $small-screen-max) {
			top: 0;
		}
	}
}

.c_master-nav__menu {
	background-color: $color-miljostatus--grey-blue-3;
	box-sizing: border-box;
	font-weight: normal;
	right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 555 !important;

	@media (min-width: $large-screen-min) {
        height: auto !important;
        padding: 0;

        // Use the following for "reveal-mode"
        left: 0px;
        transition: all 0.2s ease 0s !important;
    }

	.bm-menu {
        width: 100% !important;

        @media (min-width: $large-screen-min) {
            overflow: hidden !important;
			padding: 0;
			margin: 0 auto;
			max-width: calc(1051px + 3.7em);
        }

        &::-webkit-scrollbar {
            width: 0 !important
        }
	}

	.c_close-button {
		&__container {
			justify-content: flex-end;
			@media (min-width: $large-screen-min) {
				display: flex;
			}
		}

		@include removeButtonAppearance();
		font: {
			family: $light-italic-font-family !important;
			size: 0.845em;
		}
		display: flex;
		background-color: $color-miljostatus--dark-blue-1;
		padding: 1em 1.5em;
		margin-right: 0;
		margin-top: 0;

		.c_close-button__text {
			color: #fff;
			display: inline-block;
			margin-top: 0.015em;
		}

		img {
			height: 0.77em;
			width: 0.77em;
			margin-left: 0.88em;
		}

		@include hover() {
			.c_close-button__text {
				text-decoration: underline;
			}
		}

		&--focus-to-show {
			opacity: 0;
			border: $color--mariner solid $focus-outline-width;
			pointer-events: none;
			text-decoration: underline;
			position: relative;

			&:focus {
				opacity: 1;
				pointer-events: all;
			}
		}
	}

	.c_search-field {
		width: 100%;
		margin-bottom: 1em;
	}

	ul {
		list-style-type: none;
		padding: 0;
	}

	.c_nav-section-list {
		margin: 0;

		&__level-btns-wrapper {
			opacity: 0;
			transition: opacity 0.4s ease 0s;
			display: flex;
			flex-direction: row;

		}

		.c_nav-section-list__item {
			padding-bottom: 2em;

			&__header {
				display: flex;
    			justify-content: space-between;
    			flex-direction: column-reverse;

				@media (max-width: $small-screen-max) {
					flex-direction: row;
				}
			}

			.c_section-list-item__level-up-button {
				@include removeButtonAppearance();
				font: {
					family: $base-font-family;
					size: 0.9em;
				}
				margin-top: 5px;
				margin-right: 1em;
				margin-bottom: 0.35em;
				padding: 0.8em 0 0.61em;
				@include transitionStandard();
				display: flex;
				align-items: center;

				@include hover() {
					span {
						text-decoration: underline;
					}
				}

				@media (min-width: $large-screen-min) {
					margin-top: 0;
					margin-bottom: 0.7em;
				}

				img {
					height: 13px;
					width: 7px;
					padding-left: 1em;
					display: inline-block;
					transform: rotate(180deg);
				}

				span {
					display: inline-block;
					margin-top: .3em;
					text-align: left;
				}
			}

			.c_section-list-item {
				&__title {
					color: $color--black;
					display: block;
					font-size: 1em;
					border-bottom: 1px solid $color-miljostatus--menu-border-grey;
					margin: 0;
					padding: 0.7em $container-padding;
					text-decoration: none;
					outline: none;
					font-family: $base-font-family;

					@media (min-width: $large-screen-min) {
						padding: 0.7em 0;
						font-size: 1.222em;
					}

					@include hover() {
						text-decoration: underline;
					}

					.c_section-list-item-title__text {
						display: inline-block;
						width: 85%;
					}

					.c_section-list-item-title__label {
						width: 15%;
						font: {
							family: $light-italic-font-family;
							size: 0.833em;
						}
						float: right;
						text-align: right;

						@media (min-width: $large-screen-min) {
							font-size: 0.7em;
							line-height: 2em;
						}
					}
				}
			}
		}

		&.c_nav-section-list--sub-level {
			.c_section-list-item__title {
				margin-top: 1.5em;
			}

			.c_nav-list .c_nav-list__item {
				button, a {
					font-family: $light-font-family !important;
					padding: 0.83em 0 0.95em 0;

					@media (max-width: $small-screen-max) {
						font-size: 0.889em;
					}

					@media (min-width: $large-screen-min) {
						padding: 0.91em 0.455em 0.9em 0.455em;
					}
				}
			}

			@media (max-width: $small-screen-max) {
				.c_nav-section-list__level-btns-wrapper {
					display: flex;
					flex-direction: column;
					padding: .7em .5em;

					.c_section-list-item__level-up-button {
						width: 100%;
						margin-top: 0;
						margin-bottom: .5em;
						padding-bottom: .5em;
						padding-top: 0;
						font-size: 1em;

						img {
							height: .9em;
							width: .9em;
							padding-left: .5em;
						}

						&--first {
							margin-top: .3em;
							margin-bottom: .5em;
							padding-bottom: .5em;
							padding-top: .8em;

							span {
								padding-left: .5em;
							}
						}
					}
				}
			}
		}

		.c_nav-list {
			@media (max-width: $small-screen-max) {
				margin-top: 0;
			}

			@media (min-width: $large-screen-min) {
				&--two-column {
					column-count: 2;
					column-gap: 3em;
				}
			}

			&--sub-level {
				margin: 0;

				display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                margin: 0;

                &:after {
                    content: "";
                    display: flex;
                    width: 48%;
				}
			}

			.c_nav-list__item {
				border-bottom: 1px solid $color-miljostatus--menu-border-grey;
				padding: 0 $container-padding;
				display: inline-block;
				width: 100%;
				box-sizing: border-box;

				@media (min-width: $large-screen-min) {
					padding: 0 .5em 0 .5em;
				}

				> div {
					@media (min-width: $large-screen-min) {
						margin-left: -0.455em;
						margin-right: -0.455em;
					}
				}

				&--header {
					font-size: 1.222em;
				}

				button {
					@include removeButtonAppearance();
				}

				button, .c_nav-list__item-content {
					width: 100%;
					font: {
						family: $base-font-family;
						size: 1em;
					}
					text-align: left;
					padding: 0.98em 0 1.05em 0;
					display: flex;
					align-items: center;
					justify-content: space-between;
					line-height: 1;
					box-sizing: border-box;

					@media (min-width: $large-screen-min) {
						padding: 0.8em 0.455em 0.78em 0;
					}

					&:focus {
						span {
							text-decoration: underline;
						}
					}

					@include hover() {
						span {
							text-decoration: underline;
						}
					}

					&--active {
						@include hover() {
							span {
								text-decoration: none;
							}
						}

						@media (max-width: $small-screen-max) {
							font-size: 0.889em;
						}
					}

					span {
						width: 87%;
						display: inline-block;
						flex: 0 1 auto;
						margin-top: 0.2em;
						line-height: 1.2;

						@media (min-width: $large-screen-min) {
							width: 90%;
							line-height: 1;
						}
					}

					.c_nav-list-item__icon {
						height: 0.9em;
						width: 1.1em;
						float: left;
						flex: 0 0 auto;
						margin-top: -0.1em;
						margin-right: 0.35em;
						margin-left: 0;

						@media (min-width: $large-screen-min) {
							margin-left: 0;
							margin-right: 0.6em;
							margin-top: 0;
						}
					}

					.c_nav-list-item__arrow-right {
						height: 0.7em;
						width: 0.385em;
						float: right;
						flex: 0 0 auto;
						margin-top: -0.1em;
						margin-right: 0.3em;

						@media (min-width: $large-screen-min) {
							margin-right: 0;
						}
					}

					.c_nav-list-item__redirect-icon {
						height: 0.7em;
						width: 0.7em;
						float:right;
						flex: 0 0 auto;
						margin-top: -0.1em;
						margin-right: 0.3em;

						@media (min-width: $large-screen-min) {
							margin-right: 0;
						}
					}
				}

				button.c_nav-list__item-content, a.c_nav-list__item-content {
					@include hover() {
						span {
							text-decoration: underline;
						}
					}
				}

				a, link, a:visited {
					color: $color--black;
					text-decoration: none;
				}

				&--two-column {
					font-size: 0.889em;
					display: block;
					border-bottom: 1px solid $color-miljostatus--menu-border-grey;

					@media (min-width: $large-screen-min) {
						column-break-inside: avoid;
						-webkit-column-break-inside: avoid;
					}

					button .c_nav-list-item__arrow-right {
						height: 0.55em;
					}
				}

				&--sub-level {
					width: 48%;
					max-width: 48%;
					font-size: .888em;
					font-family: $light-font-family;
					border-bottom-color: $color-miljostatus--menu-border-grey;

					@media (max-width: $small-screen-max) {
						width: 100%;
						max-width: 100%;
					}

					&:hover,
					&--active {
						background-color: $color-miljostatus--menu-hover;
						text-decoration: underline;
					}

					.c_nav-list__item-content {
						font-family: $light-font-family;
					}
				}

				&--level-block1 {
					@media (max-width: $small-screen-max) {
						padding: 0;

						> .c_nav-list__item-content {
							padding-left: 10px;
						}
					}

					&:first-of-type {
						border-bottom: 0;
						padding-top: 0;
						margin-top: 0;
					}

					.c_nav-list--sub-level {
						.c_nav-list__item {
							> div {
								margin: 0;
							}
						}
						.c_nav-list__item-content {
							border-bottom: 0;
						}
					}

					.c_nav-list__item-content {
						border-bottom: 1px solid $color-miljostatus--menu-border-grey;
					}
				}

				&--level-block2 {
					width: 48%;
					max-width: 48%;
					border-top: 0 !important;
					border-bottom-color: $color-miljostatus--menu-border-grey;

					@media (max-width: $small-screen-max) {
						width: 100%;
						max-width: 100%;
					}
				}

				&--level-block3 {
					span {
						font-size: 0.888em;
					}

					.c_nav-list__item-content {
						padding-top: 0.77em;
						padding-bottom: 0.77em;
						font-family: $light-font-family !important;
					}

					.c_nav-list-item__arrow-right {
						height: 0.5em !important;
						width: 0.3em !important;
					}
				}

				.c_nav-list-item__warning-icon {
					width: 1.7em;
					height: 1.7em;
					margin-right: 0.7em;
				}
			}

			li[class="c_nav-list__item c_nav-list__item--level-block2"],
			li[class="c_nav-list__item c_nav-list__item--level-block3"],
			li[class="c_nav-list__item c_nav-list__item--level-block4"] {
				margin-top: 3em;
				border-top: 1px solid $color-miljostatus--menu-border-grey;

				@media (min-width: $large-screen-min) {
					margin-top: 3.23em;
				}
			}

			li[class="c_nav-list__item c_nav-list__item--level-block2"] ~ li[class="c_nav-list__item c_nav-list__item--level-block2"],
			li[class="c_nav-list__item c_nav-list__item--level-block3"] ~ li[class="c_nav-list__item c_nav-list__item--level-block3"],
			li[class="c_nav-list__item c_nav-list__item--level-block4"] ~ li[class="c_nav-list__item c_nav-list__item--level-block4"]{
				margin-top: 0;
				border-top: none;
			}

			&--top-level {
				&--flex {
					display: flex;
					justify-content: space-between;
					flex-wrap: wrap;
					margin: 2em 0 0 0;

					&:after {
						content: "";
						display: flex;
						width: 48%;
					}

					li[class="c_nav-list__item c_nav-list__item--level-block2"],
					li[class="c_nav-list__item c_nav-list__item--level-block3"] {
						margin-top: 0;
					}
				}
			}

			&--level3 {
				margin-top: 3em;
			}
		}
	}
}

.c_master-nav--open {
    @media (min-width: $large-screen-min) {
        .c_master-nav__menu {
            position: absolute !important;
            left: 0;
        }
    }
}


body.u_master-nav-menu-open {
    @media (max-width: $small-screen-max) {
        overflow: hidden;
        position: fixed;
        top: 0;

        .c_master-header {
            overflow: hidden;
        }
    }
}

/* IE9 fix */
body.u_master-nav-menu-open .c_master-nav {
	@media (min-width: $large-screen-min) {
		opacity: 1;
	}
}
