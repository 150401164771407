@import '_variables.scss';
@import '_mixins.scss';

.g_row {
	display: block;
	width: 100%;
	overflow: hidden;

	&--accept-overflow {
		overflow: visible;
		clear: both;
	}
}

.g_container {
	box-sizing: border-box;
	display: block;
	margin: 0 auto;
	width: 100%;
	max-width: $container-full-maxwidth;
	overflow: visible;
	padding: 0 $container-padding;
	position: relative;

	&\@tablet-and-desktop {
		@media (min-width: $small-screen-max) {
			box-sizing: border-box;
			display: block;
			margin: 0 auto;
			width: 100%;
			max-width: $container-full-maxwidth;
			overflow: visible;
			padding: 0 $container-padding;
			position: relative;
		}
	}

	&--small {
		max-width: $container-small-maxwidth;
		@include containerLargeWidthChange();

		.g_container--medium {
			width: 100vw;
			position: relative;
			left: 50%;
			right: 50%;
			margin-left: -50vw;
			margin-right: -50vw;
			padding-left: $container-padding;
			padding-right: $container-padding;

			@media (min-width: $small-screen-max) {
				width: $container-medium-maxwidth;
				margin-left: calc(#{$container-medium-maxwidth} / -2);
				margin-right: calc(#{$container-medium-maxwidth} / -2);
			}
		}

		.g_container--full, .g_container--max {
			width: 100vw;
			position: relative;
			left: 50%;
			right: 50%;
			margin-left: -50vw;
			margin-right: -50vw;
			padding-left: $container-padding;
			padding-right: $container-padding;

			@media (min-width: $container-full-maxwidth) {
				width: $container-full-maxwidth;
				margin-left: calc(#{$container-full-maxwidth} / -2);
				margin-right: calc(#{$container-full-maxwidth} / -2);
			}

			&\@tablet-and-desktop {
				@media (min-width: $container-small-maxwidth) {
					width: 100vw;
					position: relative;
					left: 50%;
					right: 50%;
					margin-left: -50vw;
					margin-right: -50vw;
					padding-left: $container-padding;
					padding-right: $container-padding;

					@media (min-width: $container-full-maxwidth) {
						width: $container-full-maxwidth;
						margin-left: calc(#{$container-full-maxwidth} / -2);
						margin-right: calc(#{$container-full-maxwidth} / -2);
					}
				}
			}
		}
	}

	&--medium {
		max-width: $container-medium-maxwidth;
		@include containerLargeWidthChange();
	}

	&--full {
		max-width: $container-full-maxwidth;
		@include containerLargeWidthChange();

		.g_container--small {
			@media (max-width: $small-screen-max) {
				padding-left: 0;
				padding-right: 0;
			}
		}

		&\@tablet-and-desktop {
			@media (min-width: $small-screen-max) {
				width: 85%;
				position: relative;
				margin: 0 auto;
				padding-left: $container-padding;
				padding-right: $container-padding;
			}
		}
	}
}

.g_col {
	display: block;
	width: 100%;
	min-height: 1px;

	&.g_col--small-6 {
		float: left;
		width: 50%;
	}

	@media (min-width: $large-screen-min) {
		box-sizing: border-box;
		float: left;
		padding-right: 3%;

		&:last-of-type {
			padding-right: 0;
		}

		&.g_col--is-last {
			padding: 0;
			float: none;
		}

		&.g_col--large-1 {
			width: 8.33333333333333%;
		}

		&.g_col--large-2 {
			width: 16.66666666666667%;
		}

		&.g_col--large-3 {
			width: 25%;
		}

		&.g_col--large-4 {
			width: 33.33333333333333%;
		}

		&.g_col--large-5 {
			width: 41.66666666666667%;
		}

		&.g_col--large-6 {
			width: 50%;
		}

		&.g_col--large-7 {
			width: 58.333333333333336%;
		}

		&.g_col--large-8 {
			width: 66.66666666666667%;
		}

		&.g_col--large-9 {
			width: 75%;
		}

		&.g_col--large-10 {
			width: 83.33333333333334%;
		}

		&.g_col--large-11 {
			width: 91.66666666666666%;
		}

		&.g_col--large-12 {
			width: 100%;
		}
	}

	@media (min-width: $xl-screen-min) {
		box-sizing: border-box;
		float: left;
		padding-right: 3%;

		&.g_col--xlarge-2 {
			width: 16.66666666666667%;
		}
	}

	> .g_container {
		padding: 0;
	}
}