@import '../../styles/colorsMiljostatus';
@import '../../styles/variables';
@import '../../styles/mixins';

.c_survey-pop-up {
    position: fixed;
    z-index: 999;
    bottom: 3em;
    right: 1.5em;
    animation: .4s ease-out 0s 1 slideInFromRight;
    max-width: 400px;

    @media screen and (max-width: $extra-small-screen-max) {
        width: 90%;
        max-width: none;
        right: 1em;
        bottom: 1em;
    }

    &__inner {
        padding: 1em;
        background: #fff;
        border-radius: .5em;
        box-shadow: 2px 2px 6px $color-miljostatus--dark-blue-4;
    }

    &__icon-wrapper {
        position: absolute;
        top: -15px;
        left: -15px;
        border-radius: 50%;
        border: 2px solid $color-miljostatus--dark-blue-1;
        width: 1em;
        height: 1em;
        padding: .3em;
        background: #fff;
    }
    &__icon {
        width: 1em;
        height: 1em;
    }

    // Outer close button
    &__close-button {
        @include removeButtonAppearance();
        position: absolute;
        top: -15px;
        right: -15px;
        display: flex;
        align-items: center;
        margin: 0;
        padding: .6em;
        border-radius: 50%;
        border: 2px solid #fff;
        background-color: $color-miljostatus--dark-blue-1;
    }
    &__close-icon {
        width: 1em;
        height: 1em;
    }

    &__header {
        margin: 0;
        font-size: 1.2em;
    }

    p {
        font-size: .8em;
    }

    &__actions {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &--no {
            @include removeButtonAppearance();
            font-size: .8em;
            padding: 0.675em 1.5em 0.6em 0;
            color: $color-miljostatus--dark-blue-1;
            text-decoration: underline;
            transition: all .2s linear;

            &:hover {
                color: $color-miljostatus--dark-blue-1--hover;
            }
        }

        &--yes {
            @include removeButtonAppearance();
            display: flex;
            gap: .6em;
            align-items: center;
            font-size: .9em;
            padding: 0.675em 1.5em 0.6em 1.5em;
            background-color: $color-miljostatus--dark-blue-1;
            color: #fff;
            transition: all .2s linear;

            &:hover {
                background-color: $color-miljostatus--dark-blue-1--hover;
            }
        }

        &__icon {
            width: .7em;
            height: .7em;
        }
    }
}
